import { AD_ERROR } from '../actions.js';

function omit(obj, keys) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key))
  );
}

export const adMiddleware = () => (next) => (action) => {
  const state = next(action);
  if (action.type === AD_ERROR && state.ad.logVideoAdEvents) {
    try {
      state.ad.logVideoAdEvents({
        type: action.payload.type,
        detail: omit(action.payload.event.detail, ['adTag', 'container']),
        vastUrl: state.ad.vastUrl,
        mediaId: state.mediaId,
        playerId: state.playerId,
      });
    } catch (error) {
      console.error('Error logging ad event', error);
    }
  }
  return state;
};
