import bootstrapFlowplayer from '../../assets/flowplayer.js';
import { getImaConfig } from '../../utils/prebidInstreamHandler.js';

import { selectPlayerConfig } from '../selectors.js';
import { LOAD_PLAYER, actions } from '../actions.js';

export const loadPlayerMiddleware = (store) => (next) => (action) => {
  if (action.type !== LOAD_PLAYER) {
    return next(action);
  }
  const element = action.payload;
  const { isAudio, isAdsDisabled, isDebugLogging, playerId, mediaId } =
    store.getState();
  Promise.all([
    bootstrapFlowplayer({
      element,
      isAdsDisabled,
      isDebugLogging,
      playerId,
      mediaId,
    }),
    import('./adpLogger.js').catch(console.warn),
  ]).then(([{ flowplayer, ovpConfig }, adpLogger]) => {
    store.dispatch(actions.ovpConfigLoaded(ovpConfig));
    const player = flowplayer(element.querySelector('.flowplayer'), {
      ...selectPlayerConfig(store.getState()),
      ...(!isAdsDisabled && !isAudio && getImaConfig(store)),
    });
    store.dispatch(actions.playerLoaded(player));
    if (!isAudio && adpLogger) {
      store.dispatch(actions.adpLoggerSdkLoaded(adpLogger));
    }
  });
  return next(action);
};
